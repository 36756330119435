<template>
  <b-sidebar
    id="add-new-account-sidebar"
    :visible="isAddNewAccountSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-account-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">Add New Account</h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(createRosterAccount)" @reset.prevent="resetForm">
          <!-- is existing student -->
          <validation-provider #default="validationContext" name="Existing Student?" rules="required">
            <b-form-group
              label="Existing Student?"
              label-for="isExistingStudent"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="isExistingStudent"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false, disabled: true },
                ]"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="isExistingStudent"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div v-if="isExistingStudent">
            <validation-provider #default="validationContext" name="Existing Student?" rules="required">
              <b-form-group label="Student" label-for="student">
                <vue-autosuggest
                  id="student"
                  ref="autocomplete"
                  v-model="studentQuery"
                  :suggestions="studentSuggestions"
                  :input-props="studentInputProps"
                  :section-configs="studentSectionConfigs"
                  :render-suggestion="studentRenderSuggestion"
                  :get-suggestion-value="studentGetSuggestionValue"
                  @input="studentFetchResults"
                />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Status -->
            <validation-provider #default="validationContext" name="Status" rules="required">
              <b-form-group label="Status" label-for="status" :state="getValidationState(validationContext)">
                <v-select
                  v-model="accountData.status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="account-status"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </div>
          <div v-else class="text-center">
            Creating a roster account is not currently supported without an existing student account.

            <br /><br />

            <b-link :to="{ name: 'apps-students-list', query: { op: 'create', return: 'apps-roster-account-list' } }">
              Click here to create a student account
            </b-link>
          </div>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button-group class="w-100">
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-secondary" @click="hide">
                Cancel
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                :disabled="!isExistingStudent"
                @click="createRosterAccount(false)"
              >
                Create
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                :disabled="!isExistingStudent"
                @click="createRosterAccount(true)"
              >
                Create + Edit
              </b-button>
            </b-button-group>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BButtonGroup,
  BLink,
  BFormDatepicker,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { onUnmounted, ref } from '@vue/composition-api';
import { required, alphaNum, email } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import store from '@/store';
import router from '@/router';
import { VueAutosuggest } from 'vue-autosuggest';
import useAccountsList from './useAccountsList';
import receivableStoreModule from '../receivableStoreModule';
import { useRouter } from '@core/utils/utils';
import studentStoreModule from '@/views/apps/student/studentStoreModule';

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BButtonGroup,
    BLink,
    BFormDatepicker,
    vSelect,
    VueAutosuggest,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewAccountSidebarActive',
    event: 'update:is-add-new-account-sidebar-active',
  },
  props: {
    isAddNewAccountSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isExistingStudent: false,
      student: {},

      required,
      alphaNum,
      email,
    };
  },
  methods: {
    studentFetchResults() {
      const { query } = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        const usersPromise = this.$store.dispatch('app-student/fetchStudents', {
          search: this.studentQuery,
        });

        Promise.all([usersPromise]).then((values) => {
          this.studentSuggestions = [];

          const users = values[0].data.results;

          users.length && this.studentSuggestions.push({ name: 'users', data: users });
        });
      }, 250);
    },
    studentRenderSuggestion(row) {
      return `${row.item.studentId} ${row.item.fullName}`;
    },
    studentGetSuggestionValue(row) {
      if (row.item) {
        return this.studentRenderSuggestion(row);
      }
      return `${row.studentId} ${row.fullName}`;
    },

    async createRosterAccount(follow) {
      if (!this.studentSelected || !this.studentSelected.id) {
        console.log(`invalid student`);
        return false;
      }

      const payload = {
        student: this.studentSelected.id,
        status: this.status,
      };

      store
        .dispatch('app-accounting-receivable/addAccount', payload)
        .then((response) => {
          console.log(`created account`, response);

          const { data: account } = response;

          if (follow) {
            return this.$router.push({
              name: 'apps-accounting-receivable-account-edit',
              params: { id: account.id },
            });
          }

          if (this.$route.query.return) {
            return this.$router.push({
              name: this.$route.query.return,
              params: { id: payload.student },
              query: {
                op: 'ar.account.assign',
                accountId: account.id,
              },
            });
          }

          this.$emit('refetch-data');
        })
        .catch((error) => {
          console.error(`failed to create account`, error);
        })
        .finally(() => {
          this.$emit('update:is-add-new-account-sidebar-active', false);
        });
    },
  },
  mounted() {
    const { studentId } = this.$route.query;

    if (studentId) {
      store
        .dispatch('app-student/fetchStudent', {
          id: studentId,
        })
        .then((response) => {
          this.isExistingStudent = true;
          this.studentQuery = this.studentGetSuggestionValue(response.data);
          this.studentSelected = response.data;
          this.accountData.student = response.data;
        });
    }
  },
  setup(props, { emit }) {
    const blankAccountData = {
      name: '',
      student: {},
      program: {
        name: '',
        startDate: undefined,
        endDate: undefined,
      },
      status: 'unpaid',
    };

    const STUDENT_APP_STORE_MODULE_NAME = 'app-student';
    const ACCOUNT_APP_STORE_MODULE_NAME = 'app-accounting-receivable';

    // Register module
    if (!store.hasModule(STUDENT_APP_STORE_MODULE_NAME))
      store.registerModule(STUDENT_APP_STORE_MODULE_NAME, studentStoreModule);

    // Register module
    if (!store.hasModule(ACCOUNT_APP_STORE_MODULE_NAME))
      store.registerModule(ACCOUNT_APP_STORE_MODULE_NAME, receivableStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STUDENT_APP_STORE_MODULE_NAME)) store.unregisterModule(STUDENT_APP_STORE_MODULE_NAME);
      if (store.hasModule(ACCOUNT_APP_STORE_MODULE_NAME)) store.unregisterModule(ACCOUNT_APP_STORE_MODULE_NAME);
    });

    const accountData = ref(JSON.parse(JSON.stringify(blankAccountData)));
    const resetaccountData = () => {
      accountData.value = JSON.parse(JSON.stringify(blankAccountData));
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetaccountData);

    const studentQuery = ref('');
    const studentSuggestions = ref([]);
    const studentSelected = ref({});
    const studentSectionConfigs = {
      users: {
        limit: 10,
        label: 'Students',
        onSelected: (selected) => {
          studentSelected.value = selected.item;
        },
      },
    };

    const studentInputProps = {
      id: 'autosuggest__input_ajax',
      placeholder: 'Search for students',
      class: 'form-control',
      name: 'ajax',
    };

    const {
      statusOptions,
      clearanceOptions,
      relocatableOptions,
      remoteOptions,
      fundingSourceOptions,
      paymentStatusOptions,
      submittedToVaOptions,
      uploadedCoeOptions,
      uploadedResumeOptions,
    } = useAccountsList();

    return {
      accountData,
      // onSubmit,

      statusOptions,

      studentQuery,
      studentSuggestions,
      studentSectionConfigs,
      studentInputProps,
      studentSelected,

      refFormObserver,
      getValidationState,
      resetForm,

      //

      fundingSourceOptions,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';

pre {
  min-height: 295px;
  padding: 1.5rem;
  margin-bottom: 0;
  border-radius: 0.5rem;
}

.attr-tag {
  float: right;
  margin: 2.5px 0 2.5px 5px;
}

#add-new-account-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
