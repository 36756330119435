<template>
  <div>
    <account-list-add-new
      :is-add-new-account-sidebar-active.sync="isAddNewAccountSidebarActive"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <account-list-filters
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
      :create-at-start-filter.sync="createAtStartFilter"
      :create-at-end-filter.sync="createAtEndFilter"
      :funding-filter.sync="fundingFilter"
      :funding-options="fundingSourceOptions"
      :balance-filter.sync="balanceFilter"
      :payment-date-filter.sync="paymentDateFilter"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2 mb-0">
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>

            <b-button v-if="false" :to="{ name: 'apps-roster-search-account' }" class="ml-1" variant="primary">
              Advanced Search
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
              <b-button
                v-if="hasApplicableFilter"
                variant="info"
                @click="openQueryAsSheet"
                class="mr-lg-2 mr-md-1 mr-sm-0 mb-md-0 mb-sm-1"
              >
                <span class="text-nowrap">View as Sheet</span>
              </b-button>
              <b-button variant="primary" @click="isAddNewAccountSidebarActive = true">
                <span class="text-nowrap">Create Account</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refAccountListTable"
        class="position-relative"
        :items.sync="fetchAccounts"
        responsive
        :fields="tableColumns"
        :filter.sync="searchQuery"
        :filterByFormatted="true"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :current-page.sync="currentPage"
        :per-page.sync="perPage"
      >
        <!-- Column: Student Id -->
        <template #cell(studentId)="data">
          {{ data.item.student.studentId }}
        </template>

        <!-- Column: Student Full Name -->
        <template #cell(student.fullName)="data">
          <b-link
            :to="{ name: 'apps-accounting-receivable-account-view', params: { id: data.item.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.student.fullName }}
          </b-link>
          <small class="text-muted">#{{ data.item.student.studentId }}</small>
        </template>

        <!-- Column: Cohort -->
        <template #cell(cohort)="data">
          <div class="text-nowrap" v-if="data.item.cohort">
            <b-link
              :to="{ name: 'apps-accounting-receivable-cohort-view', params: { id: data.item.cohort.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.cohort.name }}
            </b-link>
            <small class="text-muted">{{ title(data.item.cohort.status) }}</small>
          </div>
          <div class="text-nowrap" v-else>
            <feather-icon
              :id="`account-row-${data.item.id}-set-cohort-icon`"
              icon="PlusCircleIcon"
              class="cursor-pointer"
              size="16"
              @click="startCohortSelect(data.item)"
            />

            <b-tooltip
              title="Assign Cohort"
              class="cursor-pointer"
              :target="`account-row-${data.item.id}-set-cohort-icon`"
            />

            Unassigned
          </div>
        </template>

        <!-- Column: Attributes - Program -->
        <template #cell(profile.attributes.fundingSource)="data">
          <b-badge
            v-if="data.item.profile"
            pill
            :variant="`light-${resolveAccountProgramVariant(data.item.profile.attributes.fundingSource)}`"
            class="text-nowrap text-capitalize"
          >
            {{ title(data.item.profile.attributes.fundingSource) }}
          </b-badge>
          <b-badge
            v-else-if="data.item.metadata && data.item.metadata.fundingSource"
            pill
            :variant="`light-${resolveAccountProgramVariant(data.item.metadata.fundingSource)}`"
            class="text-nowrap text-capitalize"
          >
            {{ title(data.item.metadata.fundingSource) }}
          </b-badge>
          <div v-else class="text-nowrap">Unassigned</div>
        </template>

        <!-- Column: status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveAccountStatusVariant(data.item.status)}`"
            class="text-nowrap text-capitalize"
          >
            {{ title(data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: total -->
        <template #cell(total)="data">
          <span class="text-nowrap">{{ money(data.item.total) }} ({{ data.item.charges.length }})</span>
        </template>

        <!-- Column: balance -->
        <template #cell(totalPaid)="data">
          <span class="text-nowrap"
            >{{ money(data.item.totalPaid) }} ({{ data.item.payments.filter((a) => a.status === 'paid').length }})</span
          >
        </template>

        <!-- Column: balance -->
        <template #cell(balance)="data">
          <span class="text-nowrap">{{ money(data.item.balance) }}</span>
        </template>

        <!-- Column: Cohort -->
        <template #cell(createdAt)="data">
          {{ moment(data.item.createdAt).format('L') }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-link :to="{ name: 'apps-accounting-receivable-account-edit', params: { id: data.item.id } }">
              <feather-icon :id="`account-row-${data.item.id}-edit-icon`" icon="EditIcon" class="cursor-pointer" size="16" />
              <b-tooltip title="Edit Account" class="cursor-pointer" :target="`account-row-${data.item.id}-edit-icon`" />
            </b-link>

            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>

              <b-dropdown-item :to="{ name: 'apps-accounting-receivable-account-edit', params: { id: data.item.id } }">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="archiveAccount(data.item.id)">
                <feather-icon icon="ArchiveIcon" />
                <span class="align-middle ml-50">Archive</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalAccounts"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <account-add-to-cohort :is-add-cohort-open.sync="isAddCohortOpen" v-on:refresh="refetchData" :account="cohortAccount" />
    <account-upload-document
      :is-upload-document-open.sync="upload.isUploadDocumentOpen"
      :account="upload.account"
      :document-category="upload.documentCategory"
      :document-type="upload.documentType"
      v-on:refresh="refetchData"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText, title } from '@core/utils/filter';
import AccountListFilters from './AccountListFilters.vue';
import useUsersList from '../../../user/users-list/useUsersList';
import useAccountsList from './useAccountsList';
import receivableStoreModule from '../receivableStoreModule';
import AccountListAddNew from './AccountListAddNew.vue';
import moment from 'moment';
import AccountUploadDocument from './components/AccountUploadDocument.vue';
import AccountAddToCohort from './components/AccountAddToCohort.vue';
import router from '@/router';

export default {
  components: {
    AccountAddToCohort,
    AccountUploadDocument,
    AccountListFilters,
    AccountListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,

    vSelect,
  },
  data: () => {
    return {
      isAddCohortOpen: false,
      cohortAccount: undefined,

      upload: {
        isUploadDocumentOpen: false,

        account: undefined,
        documentType: undefined,
        documentCategory: undefined,
      },
    };
  },
  methods: {
    money(v) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(v);
    },
    startCohortSelect(account) {
      this.cohortAccount = account;
      this.isAddCohortOpen = true;
    },
    startUpload(account, documentCategory, documentType) {
      this.upload = {
        isUploadDocumentOpen: true,
        account,
        documentCategory,
        documentType,
      };
    },
    archiveAccount(accountId) {
      store
        .dispatch('app-accounting-receivable/updateAccount', {
          id: accountId,
          accountData: {
            status: 'archived',
          },
        })
        .then((response) => {
          this.refetchData();
        });
    },
  },
  mounted() {
    if (this.$route.query.op === 'create') {
      this.isAddNewAccountSidebarActive = true;
    }
  },
  computed: {
    hasApplicableFilter() {
      return true;
    },
  },
  setup() {
    const ACCOUNT_APP_STORE_MODULE_NAME = 'app-accounting-receivable';

    // Register module
    if (!store.hasModule(ACCOUNT_APP_STORE_MODULE_NAME))
      store.registerModule(ACCOUNT_APP_STORE_MODULE_NAME, receivableStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ACCOUNT_APP_STORE_MODULE_NAME)) store.unregisterModule(ACCOUNT_APP_STORE_MODULE_NAME);
    });

    const isAddNewAccountSidebarActive = ref(false);

    const {
      fetchAccounts,
      tableColumns,
      perPage,
      currentPage,
      totalAccounts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAccountListTable,
      refetchData,

      // UI

      resolveAccountStatusVariant,
      resolveAccountProgramVariant,
      resolveAccountYNVariant,
      // Extra Filters
      statusFilter,
      statusOptions,
      fundingFilter,
      fundingSourceOptions,
      balanceFilter,

      createAtStartFilter,
      createAtEndFilter,

      //
      paymentDateFilter,
    } = useAccountsList();

    const openQueryAsSheet = async () => {
      router.push({
        name: 'apps-accounting-receivable-cohort-import',
        query: { ...router.currentRoute.query, mode: 'filter' },
      });
    };

    return {
      // Sidebar
      isAddNewAccountSidebarActive,

      fetchAccounts,
      tableColumns,
      perPage,
      currentPage,
      totalAccounts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAccountListTable,
      refetchData,

      // Filter
      avatarText,
      title,

      // UI
      resolveAccountStatusVariant,
      resolveAccountProgramVariant,
      resolveAccountYNVariant,

      // Extra Filters
      statusFilter,
      statusOptions,
      fundingFilter,
      fundingSourceOptions,

      createAtStartFilter,
      createAtEndFilter,
      balanceFilter,
      //
      paymentDateFilter,

      //
      openQueryAsSheet,

      //
      moment,
    };
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
