<template>
  <b-card-actions title="Filters" action-collapse collapsed>
    <b-card-text>
      <b-row>
        <b-col cols="12" md="2" className="mb-md-0 mb-2">
          <label>Status</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
          />
        </b-col>
        <b-col cols="12" md="3" className="mb-md-0 mb-2">
          <label>Funding Source</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="fundingFilter"
            :options="fundingOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:fundingFilter', val)"
          />
        </b-col>
        <b-col cols="12" md="3" className="mb-md-0 mb-2">
          <label>Pathway / Subject</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="fundingFilter"
            :options="[]"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:fundingFilter', val)"
          />
        </b-col>
        <b-col v-if="false" cols="12" md="3" className="mb-md-0 mb-2">
          <small>// todo: fix</small>
          <label>Balance</label>
          <!-- slider -->
          <vue-slider
            @input="(val) => $emit('update:balanceFilter', val)"
            :value="balanceFilter"
            :min-range="1"
            :max-range="15000"
            direction="ltr"
            class="mt-1 pt-1"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="3" className="mb-md-0 mb-2">
          <label>Scheduled For</label>
          <flat-pickr
            @on-change="(val) => onPmtDateSelect(val)"
            :value="pmtDateFilter"
            class="form-control"
            :config="{ altInput: true, altFormat: 'F j, Y', dateFormat: 'Z', mode: 'range' }"
          />
        </b-col>
        <b-col cols="12" md="3" className="mb-md-0 mb-2">
          <label>Paid At</label>
          <flat-pickr
            @on-change="(val) => onPmtDateSelect(val)"
            :value="pmtPaidDateFilter"
            class="form-control"
            :config="{ altInput: true, altFormat: 'F j, Y', dateFormat: 'Z', mode: 'range' }"
          />
        </b-col>

        <b-col v-if="false" cols="12" md="3" className="mb-md-0 mb-2">
          <label>Payment Status</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-text>
  </b-card-actions>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol, BCardText, BFormDatepicker } from 'bootstrap-vue';
import vSelect from 'vue-select';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import store from '@/store';
import receivableStoreModule from '../receivableStoreModule';
import { onUnmounted, ref } from '@vue/composition-api';
import VueSlider from 'vue-slider-component';
import flatPickr from 'vue-flatpickr-component';

export default {
  components: {
    VueSlider,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormDatepicker,
    BCardActions,
    BCardText,
    vSelect,
    flatPickr,
  },
  data: () => {
    return {
      includeEnrolledOptions: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
      ],
    };
  },
  methods: {},
  props: {
    statusFilter: {
      type: [String, null],
      default: null,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    createAtStartFilter: {
      type: [String, null],
      default: null,
    },
    createAtEndFilter: {
      type: [String, null],
      default: null,
    },

    fundingFilter: { type: [String, null], default: null },
    fundingOptions: { type: Array, required: true },
    balanceFilter: { type: [[Number], null], default: [0, 15000] },

    paymentDateFilter: { default: null },
  },
  mounted() {},
  setup(props, { emit }) {
    const PLACEMENT_APP_STORE_MODULE_NAME = 'app-accounting-receivable';

    // Register module
    if (!store.hasModule(PLACEMENT_APP_STORE_MODULE_NAME))
      store.registerModule(PLACEMENT_APP_STORE_MODULE_NAME, receivableStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PLACEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(PLACEMENT_APP_STORE_MODULE_NAME);
    });

    const onPmtDateSelect = (val) => {
      console.log(`found date`, val);
      emit('update:paymentDateFilter', [...val]);
    };

    const pmtDateFilter = ref(props.paymentDateFilter);
    const pmtPaidDateFilter = ref();

    return { onPmtDateSelect, pmtDateFilter, pmtPaidDateFilter };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-slider.scss';
</style>
